import { Alert, background, Box, Button, ButtonGroup, Checkbox, Container, Grid, GridItem, Heading, HStack, Img, Input, Link, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Spacer, Spinner, Stack, Text, useColorMode, useColorModePreference, useColorModeValue, useDisclosure, useToast, VStack } from "@chakra-ui/react";
import { ethers } from "ethers";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as constants from "../constants"
import { useParams } from "react-router-dom"
import abi from "../abis/painter.json"
import { ChevronDownIcon } from "@chakra-ui/icons";
import { SwatchesPicker } from "react-color";
import { PixelEditor, Pencil, Tool } from '@curtishughes/pixel-editor';
import { SVG } from "@svgdotjs/svg.js";

const optimize = require('svgo/dist/svgo.browser').optimize


const shorten = (add) => {
    return add.slice(0, 5) + "..." + add.slice(-4)
}



export default function Pixel() {

    const provider2 =useMemo(()=> new ethers.providers.JsonRpcProvider("https://api.avax.network/ext/bc/C/rpc"), [])

    const provider = constants.n43114.Provider
    const signer = constants.n43114.Signer
    const contractAddress = '0x87f4E0B2b92BB4d63931Bc508ecDadAb33D4a565'
    const contract2 = useMemo(() => new ethers.Contract(contractAddress, abi, provider2), [provider2])
    const contract = useMemo(() => new ethers.Contract(contractAddress, abi, signer), [signer])
    const toast = useToast()
    const [address, setAddress] = useState("")
    const [color, setColor] = useState("000000")
    const [supply, setSupply] = useState(0)
    const [res, setRes] = useState(16)
    const handleError = (err) => {
        toast({
            title: "Error",
            description: (err.reason || err.message),
            status: "error",
            duration: 9000,
            isClosable: true,
        })
    }

    const connect = useCallback(async () => {
        try {
            await provider.request({
                method: "wallet_requestPermissions",
                params: [{
                    eth_accounts: {}
                }]
            }).then(() => provider.request({
                method: 'eth_requestAccounts'
            }))
        } catch (err) {
            console.log(err)
        }
        try {
            await provider.enable()

        } catch (err) {
            console.log(err)
        }
    }, [provider])

    const editorRef = useRef(null)
    const [editor, setEditor] = useState(null)//new PixelEditor(editorRef.current, 16, 16, new Pencil(color)))

    useEffect(() => {
        constants.changeNetwork(43114)
        if (editorRef.current && !editor) {
            setEditor(new PixelEditor(editorRef.current, 16, 16, new Pencil(color)))
        }
        contract2.artSupply().then(e=>setSupply(parseInt(e._hex)))
    }, [editorRef, color, editor, contract, contract2])

    useColorMode().setColorMode("dark")

    const mint = async () => {
        
        const svg = SVG().size(512, 512)
        //add a white background
        svg.rect(512, 512).fill("#ffffff")
        editor.pixels.forEach((pixel, y) => {
            svg.rect(512 / res, 512 / res).fill(pixel.color).move(512 / res * pixel.x, 512 / res * pixel.y)
        })
        const svgString = svg.svg().replace(/<svg[^>]*>/, '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">')
        console.log(svgString)
        const optimized = optimize(svgString, { multipass: true })
        console.log(optimized.data)
        const bytes = ethers.utils.toUtf8Bytes(optimized.data)
        const tx = await contract.paint(bytes).catch(handleError)
        await tx.wait()
        toast({
            title: "Success",
            description: "Your artwork has been added to the collection!",
            status: "success",
            duration: 9000,
            isClosable: true,
        })
    }

    return (
        <>
            <Container maxW="container.xl" centerContent>
                <VStack w="100%">
                    <Heading>You f-ing draw it!</Heading>
                    <Button onClick={connect}>{address ? shorten(address) : "Connect"}</Button>
                    <Text textAlign={'center'}>Add your artwork to a fully on-chain collection made by the community!<br />
                        The whole collection will start minting after the amount of artworks reaches 250.<br />
                        The artist of each piece will recieve half the mint price (0.25 from 0.5 AVAX) + royalties from secondary sales.</Text>
                    <Link isExternal href={"https://joepegs.com/collections/avalanche/" + contractAddress} color={'teal'} sx={{ ":hover": { textDecor: "underline" } }}>view collection</Link>
                    <Link href={"/mint"} color={'teal'} sx={{ ":hover": { textDecor: "underline" } }}>Go to mint page</Link>
                    <Text textAlign={'center'}>canvas size</Text>
                    <ButtonGroup>
                        <Button onClick={() => {
                            setEditor(new PixelEditor(editorRef.current, 8, 8, new Pencil(color)))
                            setRes(8)
                        }}>8x8</Button>
                        <Button onClick={() => {
                            setEditor(new PixelEditor(editorRef.current, 16, 16, new Pencil(color)))
                            setRes(16)
                        }}>16x16</Button>
                        <Button onClick={() => {
                            setEditor(new PixelEditor(editorRef.current, 32, 32, new Pencil(color)))
                            setRes(32)
                        }}>32x32</Button>
                        <Button onClick={() => {
                            setEditor(new PixelEditor(editorRef.current, 64, 64, new Pencil(color)))
                            setRes(64)
                        }}>64x64</Button>
                    </ButtonGroup>
                        <Box w="512px" h="512px" bg="white" borderRadius="lg" p={4}>
                            <canvas ref={editorRef} width="512" height="512" />
                        </Box>
                    
                    <ButtonGroup>
                        <Button onClick={() => {
                            editor.clear()
                        }}>Clear</Button>
                        <Button onClick={() => {
                            editor.undo()
                        }}>Undo</Button>
                        <Button onClick={() => {
                            editor.redo()
                        }}>Redo</Button>
                    </ButtonGroup>
                    <Text textAlign={'center'}>Submitted artworks: {supply}/250</Text>
                    <Text textAlign={'center'}>Adding the artwork to the collection does not mint it. <br />
                        Mint will happen after all 250 artworks have been added to the collection.</Text>
                    <Button width={'250px'} onClick={mint}>Add to collection</Button>
                    <SwatchesPicker color={color} onChange={(color) => {
                        editor.tool = new Pencil(color.hex)
                        setColor(color.hex)
                    }} />
                </VStack>
            </Container>
        </>
    )
}