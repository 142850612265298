import { GlobalStyle, theme, useColorModeValue, useStyleConfig } from "@chakra-ui/react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Mint from "./pages/mint";
import Pixel from "./pages/pixel";



function App() {

  return (
    <>
      <Routes>
        <Route path="*" element={<Pixel/>}/>
        <Route path="/Mint" element={<Mint/>}/>
      </Routes>
    </>
  );
}

export default App;
