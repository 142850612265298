import { Alert, background, Box, Button, ButtonGroup, Checkbox, Container, Grid, GridItem, Heading, HStack, Image, Input, Link, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Spacer, Spinner, Stack, Text, useColorMode, useColorModePreference, useColorModeValue, useDisclosure, useToast, VStack } from "@chakra-ui/react";
import { ethers } from "ethers";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as constants from "../constants"
import { useParams } from "react-router-dom"
import abi from "../abis/painter.json"
import { PencilHandler } from '@svg-drawing/core'
import {
    Svg,
    useDraw,
    useDrawFactory,
    useDrawEventHandler,
    usePencilHandler,
    useSvg,
} from '@svg-drawing/react'

import { ChevronDownIcon } from "@chakra-ui/icons";
import { useSvgDrawing } from "react-hooks-svgdrawing";
import { SwatchesPicker } from "react-color";
const optimize = require('svgo/dist/svgo.browser').optimize


const shorten = (add) => {
    return add.slice(0, 5) + "..." + add.slice(-4)
}



export default function Mint() {

    const provider = constants.n43114.Provider
    const signer = constants.n43114.Signer
    const contractAddress = '0x87f4E0B2b92BB4d63931Bc508ecDadAb33D4a565'
    const contract = useMemo(() => new ethers.Contract(contractAddress, abi, signer), [signer])
    const toast = useToast()
    const [address, setAddress] = useState("")
    const [color, setColor] = useState("000000")
    const [allowed, setAllowed] = useState(true)
    const [amount, setAmount] = useState(1)
    const handleError = (err) => {
        toast({
            title: "Error",
            description: (err.reason || err.message),
            status: "error",
            duration: 9000,
            isClosable: true,
        })
    }

    const connect = useCallback(async () => {
        try{
        await provider.request({
            method: "wallet_requestPermissions",
            params: [{
                eth_accounts: {}
            }]
        }).then(() => provider.request({
            method: 'eth_requestAccounts'
        }))
    }catch(err){
        console.log(err)
    }
    try{
        await provider.enable()

    }   catch(err){
        console.log(err)
    }
    }, [provider])




    useColorMode().setColorMode("dark")

    useEffect(() => {
        constants.changeNetwork(43114)
    }, [connect])

    const mint = async () => {
        const tx = await contract.mint(amount, {value:ethers.utils.parseEther((amount * 0.5).toString())}).catch(handleError)
        await tx.wait()
        toast({
            title: "Success",
            status: "success",
            duration: 9000,
            isClosable: true,
        })
    }


    return (
        <Container maxW="container.xl" centerContent p='50' gap={2}>
            <Heading>Mint "You f-ing draw it!"</Heading>
            <Text>A community collection of 250 1/1s on the avalanche blockchain.</Text>
            <Button onClick={connect}>{address?shorten(address):"Connect"}</Button>
            <Image rounded={'2xl'} src="https://pbs.twimg.com/media/FqDcXibWAAIGd2_?format=png&name=small" />
            <Text>amount: {amount}</Text>
            <Input placeholder="1" value={amount} w={'400px'} onChange={(e) => setAmount(e.target.value)} />
            <Button onClick={mint}>Mint {amount} for {amount * 0.5} AVAX</Button>

        </Container>

    );
}